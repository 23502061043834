// resume.jsx
import React from 'react';
import DownloadResume from "./downloadResume.jsx";

const ResumeComponent = () => {

  return (
    <section id="resume" className="resume">
        <div className="container">       
            <div className="section-title">
                <DownloadResume />
                <h2>Resume</h2>  
                <p>I dedicated a decade to a startup, beginning as an intern and advancing to the role of interim CTO. During this time, I relished the challenges and actively contributed to the company's growth, steering it to a profit exceeding over 1 million. Now, I am eager to embrace a fresh challenge, seek experience within a structured culture, and contribute my expertise to propel another company's growth.</p>
        </div>

        <div className="row">
            <div className="col-lg-6" data-aos="fade-up">
            <h3 className="resume-title">Sumary</h3>
            <div className="resume-item pb-0">
                <h4>AJ Allen</h4>
                <p><em>Versatile and deadline-oriented Full Stack Developer with expertise in Java, MySQL, Tomcat, HTML, and CSS. Over 10 years of experience in seamlessly integrating front-end 
                    and back-end technologies to deliver efficient and scalable web and desktop applications. From conceptualization to polished execution, adept at crafting robust solutions that meet project 
                    goals within specified timelines. </em></p>
                <ul>
                <li>Crete, NE</li>
                <li>(402) 730-3848</li>
                <li>aaronajallen@gmail.com</li>
                </ul>
            </div>

            <h3 className="resume-title">Education</h3>
            <div className="resume-item">
                <h4>Associates Degree</h4>
                <h5>2011-2013</h5>
                <p><em>Southeast Community College, NE</em></p>
                <p>Degree in Computer Information Technology with a focus in Software Development</p>
            </div>
            <div className="resume-item">
                <h4>Currently Learning</h4>
                <h5>Present...</h5>
                <p>Engaged in ongoing personal projects to deepen proficiency in React.js, Node.js, and Spring Boot MVC frameworks. Additionally, actively exploring NoSQL databases and diverse data storage collection methods to broaden knowledge and skills.</p>
            </div>
            </div>
            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            <h3 className="resume-title">Professional Experience</h3>
            <div className="resume-item">
                <h4>Chief Technical Officer</h4>
                <h5>2020 - Present</h5>
                <p><em>Central Data Storage, Lincoln, NE </em></p>
                <ul>
                <li>Lead in the design, development, and implementation of a Policies relating the Software Development Life Cycle.</li>
                <li>Managed 2 teams overseas, one local department, and implemented training and documentation for Sales and Supports use of company products.</li>
                <li>I orchestrated the adoption of procedures designed to manage and alleviate the technical debt within the company.</li>
                <li>I played a key role in developing CI/CD processes to enhance the frequency of software releases.</li>
                <li>Participated in creating company policies regarding information security standards compliant with ISO 27001.</li>
                </ul>
            </div>
            <div className="resume-item">
                <h4>Senior Full Stack Software Developer</h4>
                <h5>2017 - 2020</h5>
                <p><em>Central Data Storage, Lincoln, NE</em></p>
                <ul>
                <li>Maintained and introduced product improvments.</li>
                <li>Designed and developed an Encrypted Messaging product to add to the Saas offering.</li>
                <li>Participated in the technical support of the product.</li>
                <li>Developed integrations to third party Customer Relationship Management Software.</li>
                <li>Developed public facing API's for customer integrations.</li>
                </ul>
            </div>
            <div className="resume-item">
                <h4>Junior Full Stack Software Developer</h4>
                <h5>2013 - 2017</h5>
                <p><em>Central Data Storage, Lincoln, NE</em></p>
                <ul>
                <li>Key contributor in developing a multifaceted SaaS Backup product.</li>
                <li>Contributed to the creation of a Client application for the SaaS Backup product.</li>
                <li>Played a crucial role in developing a Windows Service component.</li>
                <li>Contributed to the development of a Web app designed to support the SaaS Backup product and enhance user experience.</li>
                <li>Assisted in the migration of customer base to the new company SaaS offering.</li>
                </ul>
            </div>
            </div>
        </div>
        </div>
    </section>
  );
};

export default ResumeComponent;