// footer.jsx
import React from 'react';


const FooterComponent = () => {
  return (
        <footer id="footer">
        <div className="container">
        <div className="copyright">
            &copy; Copyright <strong><span>iPortfolio</span></strong>
        </div>
        <div className="credits">
            {/* All the links in the footer should remain intact. */}
            {/* You can delete the links only if you purchased the pro version. */}
            {/* Licensing information: https://bootstrapmade.com/license/ */}
            {/* Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/iportfolio-bootstrap-portfolio-websites-template/ */}
            Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
        </div>
        </div>
    </footer>
  );
};

export default FooterComponent;