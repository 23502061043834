//mobileNav.jsx
import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';

const MobileNavComponent = () => {
  const [isNavOpen, setNavOpen] = useState(false);

  const handleToggleNav = (e) => {
    e.preventDefault(); // Prevent the default behavior of the link
    
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    if(!isNavOpen){
      setNavOpen(true);
    }else{
      setNavOpen(false);
    }
    
  };


  const handleNavLinkClick = () => {
    // Close the navigation menu after a link is clicked
    setNavOpen(false);
  };

  return (
    <>
      <i className="bi bi-list mobile-nav-toggle d-xl-none" onClick={handleToggleNav}></i>
      <Collapse in={isNavOpen}>
        <nav id="navbar" className="nav-menu">
          <ul>
            <li><a href="#hero" className="nav-link scrollto active" onClick={handleNavLinkClick}><i className="bx bx-home"></i> <span>Home</span></a></li>
            <li><a href="#about" className="nav-link scrollto" onClick={handleNavLinkClick}><i className="bx bx-user"></i> <span>About</span></a></li>
            <li><a href="#resume" className="nav-link scrollto" onClick={handleNavLinkClick}><i className="bx bx-file-blank"></i> <span>Resume</span></a></li>
            <li><a href="#contact" className="nav-link scrollto" onClick={handleNavLinkClick}><i className="bx bx-envelope"></i> <span>Contact</span></a></li>
          </ul>
        </nav>
      </Collapse>
    </>
  );
};

export default MobileNavComponent;