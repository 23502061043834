// main.jsx
import React from 'react';
import AboutComponent from './about.jsx'
import ContactComponent from './contact.jsx';
import FactsComponent from './facts.jsx';
import ResumeComponent from './resume.jsx';
import SkillsComponent from './skills.jsx';

const MainComponent = () => {
  return (
    <main id="main">
      
          {/* ======= About Section ======= */}
            <AboutComponent />
          {/* End About Section */}
      
          {/* ======= Facts Section ======= */}
            <FactsComponent />
          {/* End Facts Section */}
      
          {/* ======= Skills Section ======= */}
            <SkillsComponent />
          {/* End Skills Section */}
      
          {/* ======= Resume Section ======= */}
            <ResumeComponent />
          {/* End Resume Section */}

          {/* ======= Contact Section ======= */}
            <ContactComponent />
          {/* End Contact Section */}
      
        </main>
  );
};

export default MainComponent;