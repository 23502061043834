// facts.jsx
import React from 'react';


const FactsComponent = () => {

  return (
        <section id="facts" className="facts">
        <div className="container">

        <div className="section-title">
            <h2>Facts</h2>
            <p>As a seasoned professional who has donned many hats in the tech landscape—software developer, engineer, architect, and Chief Technology Officer. This journey has been more than a career; it's been an exploration of the boundless possibilities within the realm of technology.</p>
        </div>

        <div className="row no-gutters">

            <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up">
            <div className="count-box">
                <i className="bi bi-emoji-smile"></i>
                <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="1" className="purecounter">10</span>
                <p><strong>Years of Experience</strong> Software and web apps</p>
            </div>
            </div>

            <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up" data-aos-delay="100">
            <div className="count-box">
                <i className="bi bi-journal-richtext"></i>
                <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1" className="purecounter">18</span>
                <p><strong>Projects</strong> Major Enterprise Applications</p>
            </div>
            </div>

            <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up" data-aos-delay="200">
            <div className="count-box">
            <i className="bi bi-headset"></i>
                <span>22</span>
                <p><strong>Technologies</strong> Proficient in apps and tools</p>
            </div>
            </div>

            <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up" data-aos-delay="300">
            <div className="count-box">
                <i className="bi bi-people"></i>
                <span data-purecounter-start="0" data-purecounter-end="32" data-purecounter-duration="1" className="purecounter">1.8M+</span>
                <p><strong>Lines of code</strong> Total estimated lines of code written</p>
            </div>
            </div>

        </div>

        </div>
    </section>
  );
};

export default FactsComponent;