//downloadResume.jsx
import React from "react";
const DownloadResume = () => {
    const handleDownload = () => {
        console.log("handleDownload is being called... ");
        // You can provide the path to your file here
        const filePath = process.env.PUBLIC_URL + '/pdfs/aallen_resume_2023.pdf';

        // Create a link element
        const link = document.createElement('a');

        // Set the href attribute to the file path
        link.href = filePath;

        // Set the download attribute with the desired file name
        link.download = 'AJ Allen Resume.pdf';

        // Append the link to the document
        document.body.appendChild(link);

        // Trigger a click on the link to start the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
    };

    return (
        <div style={{ float: 'right', position: 'relative', zIndex: 1000 }}>
            <button className="btn btn-primary" onClick={handleDownload} onMouseOver={() => console.log('Mouse over button')}>
                <i className="bi bi-download"></i>
            </button>
        </div>
    );
};

export default DownloadResume;