// contact.jsx
import React from 'react';


const ContactComponent = () => {
  return (
    <section id="contact" className="contact">
    <div className="container">
    
      <div className="section-title">
        <h2>Contact</h2>
        <p>Thank you for stopping by! I'm excited about the opportunity to connect. Whether you have questions, opportunities, or just want to say hello, please use the contact information below. I'm eager to hear from you and explore potential collaborations.</p>
      </div>
    
      <div className="row" data-aos="fade-in">
    
        <div className="col-lg-12 d-flex align-items-stretch">
          <div className="info">
            <div className="address">
              <i className="bi bi-geo-alt"></i>
              <h4>Location:</h4>
              <p>Crete, NE 68333</p>
            </div>
    
            <div className="email">
              <i className="bi bi-envelope"></i>
              <h4>Email:</h4>
              <p>aaronajallen@gmail.com</p>
            </div>
    
            <div className="phone">
              <i className="bi bi-phone"></i>
              <h4>Call:</h4>
              <p>+1 402 730 3848</p>
            </div>
            <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3073.466088417571!2d-96.9579952841473!3d40.62350397933892!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8796bf1a6eaf23bb%3A0xf666ab2cdaa1ba2e!2sCrete%2C%20NE%2068333%2C%20USA!5e0!3m2!1sen!2s!4v1638064983697!5m2!1sen!2s!21z21" frameBorder="0" style={{border:'0', width: '100%', height: '290px'}} allowFullScreen></iframe>
          </div>
        </div>      
      </div>
    
    </div>
    </section>
  );
};

export default ContactComponent;
