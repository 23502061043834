// hero.jsx
import React from 'react';
import MyComponent from './typedComponent';

const HeroComponent = () => {
  return (
        <section id="hero" className="d-flex flex-column justify-content-center align-items-center">
        <div className="hero-container" data-aos="fade-in">
        <h1>AJ Allen</h1>
        <p>I'm <MyComponent /></p>
        </div>
    </section>
  );
};

export default HeroComponent;