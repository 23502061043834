import './App.css';

import HeaderComponent from './components/header.jsx';
import FooterComponent from './components/footer.jsx';
import HeroComponent from './components/hero.jsx';
import MainComponent from './components/main.jsx';
import MobileNavComponent from './components/mobileNav.jsx';

function App() {
  return (
    <>
      <MobileNavComponent />

      <HeaderComponent />

      <HeroComponent />

      <MainComponent />
    
      <FooterComponent />
    </>
  );
}

export default App;
