// about.jsx
import React from 'react';


const AboutComponent = () => {
  return (
        <section id="about" className="about">
        <div className="container">

        <div className="section-title">
            <h2>About</h2>
            <p>A diligent work ethic has been a constant in my career. From starting my journey with a startup as an intern to steering the ship as a CTO, I've always approached challenges with determination. Achieving company goals isn't just a checkbox—it's a commitment to excellence, a promise to deliver beyond expectations.</p>
        </div>

        <div className="row">
            <div className="col-lg-4" data-aos="fade-right">
            <img src="assets/img/profile-img.png" className="img-fluid" alt="" />
            </div>
            <div className="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
            <h3>Full Stack Developer</h3>
            <p className="fst-italic">
            In the intricate world of coding and development, I find immense joy in problem-solving. It's not just about writing lines of code; it's about crafting solutions that stand the test of complexity. My journey has been marked by a relentless pursuit of learning new technologies, staying at the forefront of innovation to bring fresh perspectives to every project.
            </p>
            <div className="row">
                <div className="col-lg-6">
                <ul>
                    <li><i className="bi bi-chevron-right"></i> <strong>Birthday:</strong> <span>April 21</span></li>
                    <li><i className="bi bi-chevron-right"></i> <strong>Website:</strong> <span>ajallen.dev</span></li>
                    <li><i className="bi bi-chevron-right"></i> <strong>Phone:</strong> <span>+402 730 3848</span></li>
                    <li><i className="bi bi-chevron-right"></i> <strong>City:</strong> <span>Crete, NE</span></li>
                </ul>
                </div>
                <div className="col-lg-6">
                <ul>
                    <li><i className="bi bi-chevron-right"></i> <strong>Experience:</strong> <span>10 Years</span></li>
                    <li><i className="bi bi-chevron-right"></i> <strong>Degree:</strong> <span>Associates in Development</span></li>
                    <li><i className="bi bi-chevron-right"></i> <strong>email:</strong> <span>aaronajallen@gmail.com</span></li>
                    <li><i className="bi bi-chevron-right"></i> <strong>Freelance:</strong> <span>Available</span></li>
                </ul>
                </div>
            </div>
            <p>
                As a mentor and guide, I take pride in fostering an environment where knowledge flows seamlessly. Whether in the role of a deveoloper, engineer, or CTO, helping others navigate the ever-evolving tech landscape has been a cornerstone of my professional identity. I believe in building teams that thrive collectively, where each member contributes and grows.
            </p>
            </div>
        </div>

        </div>
    </section>
  );
};

export default AboutComponent;