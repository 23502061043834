// typedComponent.jsx
import React, { useEffect, useRef } from 'react';
import Typed from 'typed.js';

const MyComponent = () => {
  const typedRef = useRef(null);

  useEffect(() => {
    const options = {
      strings: ['Analytical', 'a Developer', 'Logical', 'a Problem Solver', 'Independent', 'Adaptable', 'Personable'],
      typeSpeed: 50,
      backSpeed: 50,
      loop: true,
    };

    const typed = new Typed(typedRef.current, options);

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <span className="typed" data-typed-items="Designer, Developer, Leader, Problem Solver" ref={typedRef}></span>
  );
};

export default MyComponent;