// skills.jsx
import React from 'react';


const SkillsComponent = () => {
  return (
        <section id="skills" className="skills section-bg">
        <div className="container">

        <div className="section-title">
            <h2>Skills</h2>
            <p>Throughout my professional journey, I've dedicated myself to skill development, honing a diverse set of capabilities. While many have reached a level of proficiency, I remain committed to perfecting and enhancing certain skills. The following offers an analysis of my existing knowledge base, providing insights into the breadth and depth of my expertise across various domains.</p>
        </div>

        <div className="row skills-content">

            <div className="col-lg-6" data-aos="fade-up">

            <div className="progress">
                <span className="skill">Java <i className="val">100%</i></span>
                <div className="progress-bar-wrap">
                <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}></div>
                </div>
            </div>

            <div className="progress">
                <span className="skill">HTML/CSS <i className="val">90%</i></span>
                <div className="progress-bar-wrap">
                <div className="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"style={{ width: '90%' }}></div>
                </div>
            </div>

            <div className="progress">
                <span className="skill">JavaScript/JQuery <i className="val">80%</i></span>
                <div className="progress-bar-wrap">
                <div className="progress-bar" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"style={{ width: '80%' }}></div>
                </div>
            </div>

            </div>

            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">

            <div className="progress">
                <span className="skill">MySQL <i className="val">80%</i></span>
                <div className="progress-bar-wrap">
                <div className="progress-bar" role="progressbar" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100" style={{ width: '80%' }}></div>
                </div>
            </div>

            <div className="progress">
                <span className="skill">React.js <i className="val">40%</i></span>
                <div className="progress-bar-wrap">
                <div className="progress-bar" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"style={{ width: '40%' }}></div>
                </div>
            </div>

            <div className="progress">
                <span className="skill">Spring Boot <i className="val">50%</i></span>
                <div className="progress-bar-wrap">
                <div className="progress-bar" role="progressbar" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100" style={{ width: '50%' }}></div>
                </div>
            </div>

            </div>

        </div>

        </div>
    </section>
  );
};

export default SkillsComponent;